import { useContext } from "react";
import { Container } from "react-bootstrap";
import { GlobalContext } from "../components/data/GlobalContext";
import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";

const OurVideos = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Layout pageTitle="Our Videos" >
            <PagesHeader title="Our Videos" crumbTitle="Our Videos" pageHeaderBg={dbData.gallery[85]} />
            <div className="text-center py-5">
                <h1 className="text-capitalize">Our Videos</h1>
            </div>
            <Container className="pb-5">
                <div class="elfsight-app-d275262d-e864-4080-b0df-c10d8aa45550"></div>
            </Container>
        </Layout>
    );
}
export default OurVideos;