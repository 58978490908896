
// import {GiPaintRoller} from "react-icons/gi";
import {MdOutlineRoofing} from "react-icons/md";

const IconTitle = ({ tagLine }) => {
    return (
        <div className="block-title">
            <p>
                <MdOutlineRoofing fontSize='50' color="red" />
                <span className="ms-3 text-capitalize">{tagLine}</span>
            </p>
        </div>
    );
};

export default IconTitle;