import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";
import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";
import ContactForm from "../components/contact/ContactForm";
// import FormContent from "../components/contact/FormContent";
import ContactCard from "../components/ContactCard";
import GoogleMap from "../components/GoogleMap";



const Contact = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Layout pageTitle="Conatct">
            <PagesHeader title="Contact us" crumbTitle="Contact Us" pageHeaderBg={dbData.gallery[15]}/>
            <ContactForm />
            <ContactCard />
            <GoogleMap extraClass="contact" />
        </Layout>
    );
}
export default Contact;