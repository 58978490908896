
const dbMenu = {
    dbMenu: [
        {
            name: `Home`,
            link: `/`,
        },
        {
            name: `About`,
            link: `/about`,
        },
        {
            name: `Services`,
            link: `/services`,
            submenu: [
                {
                    name: `Granite Countertops`,
                    link: `/granite-countertops`,
                },
                {
                    name: `Marble Countertops`,
                    link: `/marble-countertops`,
                },
                {
                    name: `Quartz Countertops`,
                    link: `/quartz-countertops`,
                },
            ],
        },
        {
            name: `Gallery`,
            link: `#`,
            submenu: [
                {
                    name: `Photos`,
                    link: `/photos`,
                },
                {
                    name: `Our Videos`,
                    link: `/our-videos`,
                },
            ],
        },
        {
            name: `Our Suppliers`,
            link: `#`,
            url: `https://g.co/kgs/iExzxA2`
        },
        {
            name: `Reviews`,
            link: `/reviews`,
        },
        {
            name: `Contact`,
            link: `/contact`,
        },
    ],
}

export default dbMenu;