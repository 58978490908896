import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";
import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";
import { Container } from "react-bootstrap";

const Reviews = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Layout pageTitle="Reviews" >
            <PagesHeader title="Reviews" crumbTitle="Reviews" pageHeaderBg={dbData.gallery[85]} />
            <div className="text-center py-5">
                <h1 className="text-capitalize"> Our Reviews</h1>
            </div>
            <Container className="pb-5">
                <div class="elfsight-app-3fa4bbff-48c1-41ab-8a3a-de44572bd7fb"></div>
            </Container>
        </Layout>
    );
}
export default Reviews;