import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { GlobalContext } from '../data/GlobalContext';
// import FormContent from "../contact/FormContent";
// import Forms from '../contact/Forms';
import classes from './modal.module.css';
import FormContent from "../contact/FormContent";

function ModalForm(props) {

    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const dbData = useContext(GlobalContext);

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <div className={classes.modal__header}>
                        <div className={classes.modal__close}>
                            <p className="fs-5" style={{ cursor: "pointer" }} onClick={handleClose}>X</p>
                        </div>
                        <div className={classes.modal__logo}>
                            <img src={dbData.dbPrincipal.logo} alt="logo" />
                        </div>
                    </div>
                </Modal.Header>
                <div className={classes.modal__form}>
                    <h3>Request An Estimate Now </h3>
                    {/* <FormContent style={{ width: "100%" }} /> */}
                    <FormContent />
                </div>
            </Modal>
        </div>
    );
}
export default ModalForm;
