import { useContext } from 'react';
import { GlobalContext } from '../../components/data/GlobalContext';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
// import { SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper";
import videoHome from '../../assets/video/videoHome.mp4'


SwiperCore.use([Autoplay, Pagination, EffectFade]);


const MainSlider = () => {

    const dbData = useContext(GlobalContext);

    // const mainSlideOptions = {
    //     slidesPerView: 1,
    //     loop: true,
    //     effect: "fade",
    //     pagination: {
    //         el: "#main-slider-pagination",
    //         type: "bullets",
    //         clickable: true
    //     },
    //     autoplay: {
    //         delay: 5000,
    //     },

    // };

    // const slides = (imgSlide) => {
    //     return (
    //         <SwiperSlide>
    //             <div
    //                 className="image-layer"
    //                 style={{ backgroundImage: `url("${imgSlide}")` }}
    //             ></div>
    //             <Container>
    //                 <Row className=" justify-content-start">
    //                     <Col xl={6} lg={12} className="text-left">
    //                         <h2>
    //                             {dbData.dbSlogan[2].slogan}
    //                         </h2>
    //                         <p>
    //                             {dbData.dbValues[0].description}
    //                         </p>
    //                         <Link to="/contact" className="thm-btn dynamic-radius text-capitalize">
    //                             free estimate
    //                         </Link>
    //                     </Col>
    //                 </Row>
    //             </Container>
    //         </SwiperSlide>
    //     )
    // }

    return (
        // <section className="main-slider main-slider__two">
        //     <Swiper {...mainSlideOptions}>
        //         {slides(dbData.gallery[52])}
        //         {slides(dbData.gallery[23])}
        //         {slides(dbData.gallery[94])}
        //         <div className="swiper-pagination" id="main-slider-pagination"></div>
        //     </Swiper>
        // </section>
        <section className="mainHeader">
        <Container>
                <Row className=" justify-content-start mainHeader__info">
                    <Col xl={6} lg={12} className="text-left">
                        <h2>
                            {dbData.dbSlogan[2].slogan}
                        </h2>
                        <p>
                            {dbData.dbValues[0].description}
                        </p>
                        <Link to="/contact" className="thm-btn dynamic-radius text-capitalize">
                            free estimate
                        </Link>
                    </Col>
                </Row>
            </Container>
            <video muted autoPlay loop>
                <source src={videoHome} type='video/mp4' />
            </video>
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/nH-v5I5wXOw?autoplay=1&loop=1&controls=0&playlist=nH-v5I5wXOw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </section>
    );
};

export default MainSlider;
